var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile__wrapper"},[_vm._l((_vm.getModel.group),function(group){return _c('div',{key:group.name,staticClass:"profile__block"},[_c('h4',{staticClass:"title-h4 profile__title"},[_vm._v(" "+_vm._s(_vm.$t(("profile." + (group.name))))+" ")]),_c('div',{staticClass:"profile__grid"},_vm._l((group.fields),function(item){return _c('div',{key:item.name,class:[
          { profile__file: item.type === 'file' },
          ("" + (item.column_type)) ]},[(item.type === 'file')?[_c('FileUpload',{attrs:{"id":item.name,"is-multiple":item.name === 'statistical_calls',"valid-formats":[
              'doc',
              'docx',
              'pdf',
              'img',
              'jpeg',
              'jpg',
              'png' ],"max-size-k-b":25000},on:{"uploadFiles":function($event){return _vm.uploadFiles($event, item)}},model:{value:(_vm.files[item.name]),callback:function ($$v) {_vm.$set(_vm.files, item.name, $$v)},expression:"files[item.name]"}})]:_c('text-field',{attrs:{"id":item.name,"title":_vm.$t(("profile." + (item.name))),"is-rate":item.type === 'rate',"is-icon":item.type === 'text_icon',"is-disabled":"","icon-name":"actual"},model:{value:(_vm.getCurUserInfo[item.name]),callback:function ($$v) {_vm.$set(_vm.getCurUserInfo, item.name, $$v)},expression:"getCurUserInfo[item.name]"}})],2)}),0)])}),_c('div',{staticClass:"profile__qr-code-block"},[_c('h4',{staticClass:"title-h4 profile__title"},[_vm._v(" "+_vm._s(_vm.$t('profile.user_qr_code'))+" ")]),_c('div',{staticClass:"profile__qr-code",domProps:{"innerHTML":_vm._s(_vm.getQrCode)}})]),_c('h4',{staticClass:"title-h4 eu-key__title"},[_vm._v(" "+_vm._s(_vm.$t('profile.electronic_key'))+" ")]),_c('EuKey',{staticClass:"mb-16",attrs:{"isUploadKey":!_vm.isUserHasEuKey}}),_c('Button',{staticClass:"print__btn",attrs:{"icon-name":"print"},nativeOn:{"click":function($event){return _vm.printWindow()}}},[_vm._v(_vm._s(_vm.$t('buttons.print'))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="[
            { profile__file: item.type === 'file' },
            `${item.column_type}`,
          ]"
        >
          <template v-if="item.type === 'file'">
            <FileUpload
              :id="item.name"
              v-model="files[item.name]"
              :is-multiple="item.name === 'statistical_calls'"
              :valid-formats="[
                'doc',
                'docx',
                'pdf',
                'img',
                'jpeg',
                'jpg',
                'png',
              ]"
              :max-size-k-b="25000"
              @uploadFiles="uploadFiles($event, item)"
            />
            <!-- :is-download-only="!editIsDisabled(item.uuid) && !isEditable" -->
          </template>
          <text-field
            v-else
            :id="item.name"
            v-model="getCurUserInfo[item.name]"
            :title="$t(`profile.${item.name}`)"
            :is-rate="item.type === 'rate'"
            :is-icon="item.type === 'text_icon'"
            is-disabled
            icon-name="actual"
          />
        </div>
      </div>
    </div>
    <div class="profile__qr-code-block">
      <h4 class="title-h4 profile__title">
        {{ $t('profile.user_qr_code') }}
      </h4>
      <div class="profile__qr-code" v-html="getQrCode" />
    </div>
    <h4 class="title-h4 eu-key__title">
      {{ $t('profile.electronic_key') }}
    </h4>
    <EuKey :isUploadKey="!isUserHasEuKey" class="mb-16" />
    <Button class="print__btn" icon-name="print" @click.native="printWindow()"
      >{{ $t('buttons.print') }}
    </Button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import profile from '../../models/tour_operator/profile';
import EuKey from '@/elements/euKey/EuKey.vue';
import FileUpload from '@/elements/FileUpload.vue';

export default {
  components: { EuKey, FileUpload },

  data() {
    return {
      user: {},
      files: {
        contract_from_insurance: null,
        statistical_calls: null,
      },
    };
  },
  methods: {
    printWindow() {
      window.print();
    },
  },

  computed: {
    ...mapGetters(['getAuthUserData', 'isUserHasEuKey', 'isRequestLoaded']),

    getModel() {
      return profile;
    },
    getCurUserInfo() {
      let obj = {};
      if (!!this.getAuthUserData) {
        for (let key in this.getAuthUserData) {
          let val = !!this.getAuthUserData[key]
            ? this.getAuthUserData[key]
            : '-';

          obj[key] = `${val}`;
        }
        obj.district = !!this.getAuthUserData?.district
          ? this.getAuthUserData.district.public_name
          : '-';

        obj.settlement = !!this.getAuthUserData?.settlement
          ? this.getAuthUserData.settlement.public_name
          : '-';

        obj.state = !!this.getAuthUserData?.state
          ? this.getAuthUserData.state.public_name
          : '-';
      }
      return obj;
    },

    getQrCode() {
      return this.getAuthUserData?.qr_code;
    },
  },
  created() {
    this.$store.dispatch('getAuthUserData');
    this.$store.commit('SET_CURRENT_FORM_TYPE', 'profile');
  },
};
</script>

<style lang="sass" scoped>
.profile__file
  transform: translateY(-15px)
</style>
